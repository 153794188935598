import DNS from '../../Assets/solutions/domainandhosting/DNS.png'
import Dnsmanagement from '../../Assets/solutions/domainandhosting/DnsManagement.png'
import scalablehosting from '../../Assets/solutions/domainandhosting/scalablehosting.png'
import domaintransfer from '../../Assets/solutions/domainandhosting/domaintransfer.png'
import ssl from '../../Assets/solutions/domainandhosting/ssl.png';
import emailhosting from '../../Assets/solutions/domainandhosting/emailhosting.png';




const Maintainance = [
    {
        img: DNS,
        title: "Domain Name Registration",
        alt:"DNS"

    },
    {
        img: Dnsmanagement,
        title: "DNS Management",
        alt:"Dnsmanagement"

    },
    {
        img: scalablehosting,
        title: "Scalable Hosting Plans",
        alt:"scalablehosting"
    },
    {
        img: domaintransfer,
        title: "Domain Transfer Assistance",
        alt:"domaintransfer"

    },
    {
        img: ssl,
        title: "SSL Certificate Integration",
        alt:"ssl"

    },
    {
        img: emailhosting,
        title: "Email Hosting",
        alt:"emailhosting"
    }
]

export default Maintainance;