import React, { useEffect } from "react";
import hero from "../../../Assets/solutions/domainandhosting/www.png";
import AOS from "aos";
import "aos/dist/aos.css";
const BelowSection = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <div
      className="my-16 py-16  rounded-[20px] overflow-hidden poppins"
      style={{
        background: "linear-gradient(135deg, #43CBFF 0%, #9708CC 100%)",
      }}
    >
      <h2
        className="capitalize font-bold text-center  text-white"
        style={{ fontSize: "clamp(22px,2.5vw,42px)" }}
        data-aos="fade-up"
      >
        Ensuring Your Website Stays <br /> Secure and Performs Flawlessly
      </h2>
      <div
        className="flex flex-col-reverse  md:flex-row items-center justify-center  mx-4 md:mx-10 mt-10 "
        data-aos="fade-up"
      >
        <div
          className="w-full md:w-[50%] flex justify-center items-center mt-8 md:mt-0"
          data-aos="fade-left"
        >
          <p
            style={{ fontSize: "clamp(20px,1.5vw,27px)" }}
            className=" text-white text-center  md:text-left  md:w-[70%]  leading-10 pb-8"
          >
           Dynamic Leo ensures your website stays fast and secure with proactive monitoring and tailored hosting solutions, minimizing downtime and ensuring seamless performance.
          </p>
        </div>
        <div
          className="w-full md:w-[50%] flex justify-center"
          data-aos="fade-right"
        >
          <img src={hero} alt="Web Development" className="max-h-[60vh]" />
        </div>
      </div>
    </div>
  );
};

export default BelowSection;
