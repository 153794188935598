import React,{useEffect} from 'react'
import hero_img from '../../../Assets/Hero/domain_and_hosting.png'
import greater_arrow_white from '../../../Assets//icons/greater_arrow_white.svg'
import AOS from 'aos';
import 'aos/dist/aos.css';
function Hero() {
    useEffect(() => {
        AOS.init({ duration: 1000 }); 
      }, []);
    return (
        <div className='w-full bg-[#FFEABD] max-h-[100vh] pb-[2rem] overflow-hidden'>
            <div className='w-full sectionlayout poppins'>
                <div className='block md:flex pt-[2rem] md:pt-[2.5rem]'>
                    <div className='md:w-[53%]' data-aos="fade-up-right" >
                        <div className='text-[#491609]'>
                            <h1 style={{ fontSize: 'clamp(26px,3.8vw,55px)'}} className='uppercase font-bold  leading-tight'>Improve Your Website with <span className='text-[#FF7D33] '> Premium Hosting  </span>Services </h1>
                            <p style={{ fontSize: 'clamp(16px,1.5vw, 20px)'}} className='text-[#6C6C6C] w-[80%] md:w-[83%] font-medium pl-1 pt-[1.5rem] md:pt-[2rem]'>Your website deserves the best. Discover our premium hosting solutions tailored to your needs. From reliable uptime to top-notch security, we've got you covered.</p>
                        </div>
                        <div className='md:flex pt-[1.5rem] md:pt-[2rem]'>
                            <button style={{ fontSize: 'clamp(15px,1.5vw,18px)'}} className='py-[12px] px-[20px] bg-gradient-to-r from-[#FEC163] to-[#DE4313] text-white rounded-[8px] font-semibold mr-6 mb-4 md:mb-0'>Learn More <img src={greater_arrow_white} alt="Greater Arrow" className='pl-3 inline-block h-[15px]'/></button>
                        </div>
                    </div>
                    <div className='flex items-center justify-end md:w-[50%]' data-aos="fade-left" >
                        <img src={hero_img} alt="Maintanance" className='w-[100%]' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero